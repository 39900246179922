import React from 'react';
import { graphql } from 'gatsby';
import { get, isEmpty } from 'lodash';
import createLink from 'utils/createLink';

import { MetaContainer } from 'containers/meta/MetaContainer';
import { SocialContainer } from 'containers/social/Social';
import { Slices } from 'containers/slices/Slices';

import RichText from 'components/rich-text/RichText';
import { Link } from 'components/link/Link';
import { Intro } from 'components/intro/Intro';
import { PageHeader } from 'components/page-header/PageHeader';
import { PrismicImage } from 'components/prismic-image/PrismicImage';
import { Segment } from 'components/layout/Segment';
import { Content } from 'components/content/Content';
import { fragmentMeta } from 'fragments';

interface IProps {
  data: any;
}

const Festivals = (props: IProps) => {
  const { data } = props;
  const pageData = get(data, 'prismic.allFestivalss.edges.0.node', {});
  const sliceData = get(pageData, 'body', []);

  const heroImage = !isEmpty(pageData.image) && !isEmpty(pageData.image.hero) ?
    <PrismicImage image={pageData.image} view="hero" /> : undefined;

  return (
    <>
      <MetaContainer data={pageData} />

      <PageHeader
        heading={RichText.asText(pageData.title)}
        backLink={!isEmpty(pageData.back_link) ? (
          <Link to={createLink(pageData.back_link)}>
            {RichText.asText(pageData.back_link, 'title')}
          </Link>
        ) : undefined}
        image={heroImage}
      />

      <Content>
        <SocialContainer type="sidebar" facebookLink="/" twitterLink="/" pinterestLink="/" />
        <Segment>
          <Intro>
            {RichText.render(pageData, 'description')}
          </Intro>
        </Segment>
        {!isEmpty(sliceData) &&
          <Slices data={sliceData} />
        }
      </Content>
    </>
  );
};

export const query = graphql`
  query festivals($lang: String!) {
    prismic {
      allFestivalss(lang: $lang) {
        edges {
          node {
            _meta {
              ...meta
            }
            title
            description
            image
            back_link {
              _linkType
              __typename
              ... on PRISMIC_Things_to_do {
                title
                _meta {
                  ...meta
                }
              }
              ... on PRISMIC_About_iceland {
                title
                _meta {
                  ...meta
                }
              }
              ... on PRISMIC_Plan_your_trip {
                title
                _meta {
                  ...meta
                }
              }
            }
            seo_title
            seo_description
            seo_image
            body {
              __typename
              ... on PRISMIC_FestivalsBodyCalendar {
                type
                primary {
                  calendar_month
                  calendar_year
                }
                fields {
                  calendar_link {
                    _linkType
                    __typename
                    ... on PRISMIC_Event {
                      _meta {
                        ...meta
                      }
                      title
                      image
                      location
                      link {
                        _linkType
                        __typename
                       	... on PRISMIC__ExternalLink {
                        	url
                      	}
                        ... on PRISMIC_Article {
                          _meta {
                            ...meta
                          }
                        }
                        ... on PRISMIC_Page_content {
                          _meta {
                            ...meta
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

Festivals.fragments = [fragmentMeta];

export default Festivals;
